import * as React from 'react';

export interface HeaderContextShape {
	navigationTopOffset: number;
	hasSubNavigation?: boolean;
}

export const defaultTheme: HeaderContextShape = {
	navigationTopOffset: 0,
	hasSubNavigation: false,
};

export const HeaderContext = React.createContext(defaultTheme);
