import * as React from 'react';
import Layout from 'common/layouts/Empty/Empty';
import { Helmet } from 'react-helmet';
import { MapStateToProps, connect } from 'react-redux';
import { State } from 'common/reducers';
import { pushOtherPageLoad } from 'utils/dataLayer';
import { getTwitterUserNameWithAtSign } from 'common/components/App/services';
import Hero from 'styleguide/components/HeroBanners/Hero';
import { useHeroMedia } from 'common/hooks/useHeroMedia';
import { ThemeContext } from 'styled-components';

interface HomeStateProps {
	title?: string;
	metaDescription?: string;
	canonicalUrl?: string;
	ampUrl?: string | null;
	previewImage?: string;
	twitterUserName: string | null | undefined;
}

const Home: React.FunctionComponent<HomeStateProps> = props => {
	const { title, previewImage, metaDescription, canonicalUrl, twitterUserName } = props;
	const { heroMedia, heroImageOffsetY } = useHeroMedia('home-default-hero');
	const theme = React.useContext(ThemeContext);
	React.useEffect(pushOtherPageLoad, []);
	return (
		<>
			<Helmet>
				<link rel="canonical" href={canonicalUrl} />
				<meta name="twitter:card" content="summary_large_image" />
				{twitterUserName && <meta name="twitter:site" content={twitterUserName} />}
				<meta name="twitter:url" content={canonicalUrl} />
				{previewImage && <meta name="twitter:image" content={`${previewImage}/og-image`} />}
				<meta name="description" content={metaDescription} />
				<meta property="og:site_name" content="Valio.fi" />
				<meta property="og:title" content={title} />
				<meta property="og:type" content="website" />
				<meta property="og:description" content={metaDescription || ''} />
				<meta property="og:url" content={canonicalUrl} />
				{previewImage && <meta property="og:image" content={`${previewImage}/og-image`} />}
				{previewImage && <meta property="og:image:width" content="1200" />}
				{previewImage && <meta property="og:image:height" content="900" />}
				<meta property="og:image:alt" content={title} />
			</Helmet>
			{theme.styledVariations.homePageHeroSize && (
				<Hero
					src={heroMedia.mediaSrc}
					mediaType={heroMedia.mediaType}
					offsetY={heroImageOffsetY}
					size={theme.styledVariations.homePageHeroSize}
				/>
			)}
			<Layout />
		</>
	);
};

const mapStateToProps: MapStateToProps<HomeStateProps, {}, State> = (state): HomeStateProps => {
	const { resource } = state;
	return {
		title: resource.title,
		canonicalUrl: resource.canonicalUrl,
		previewImage: resource.previewImage,
		metaDescription: resource.metaDescription,

		twitterUserName: getTwitterUserNameWithAtSign(state),
	};
};

export default connect(mapStateToProps, null)(Home);
