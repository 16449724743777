import * as React from 'react';
import WidgetZone from 'common/components/Widgets/WidgetZone';
import { CmsContentState } from 'pagetypes/CmsContent/interfaces';
import { ResourceState } from 'common/components/Resource/reducers/reducers';
import { useSelector } from 'react-redux';
import { State } from 'common/reducers';
import { ContentWrapper } from 'common/components/General';
import { HeaderContext, HeaderContextShape } from './contexts';

type SupportedContentStates = CmsContentState | null;

const Empty: React.FunctionComponent<Partial<ResourceState> & Partial<SupportedContentStates>> = () => {
	const state = useSelector(({ dom: { header }, navigation }: State) => ({
		header,
		navigation,
	}));
	const headerContext: HeaderContextShape = {
		navigationTopOffset: state.navigation.topOffset,
		hasSubNavigation: !!state.navigation.secondaryNavigation,
	};
	return (
		<HeaderContext.Provider value={headerContext}>
			<ContentWrapper>
				<WidgetZone name="Main" isFullWidth />
			</ContentWrapper>
		</HeaderContext.Provider>
	);
};

export default Empty;
